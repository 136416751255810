import "./Home.css";
import { PICTURES } from "./constants/pictures"
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { PicturesSlideshow } from "./components/PicturesSlideshow";
import { Sidebar } from "./components/Sidebar";

export const Home = () => (
  <>
    <Sidebar>
      <Header />
      <Main />
      <Footer />
    </Sidebar>
    <PicturesSlideshow element="body" pictures={PICTURES} interval={5000} />
  </>
);
